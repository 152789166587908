/**
 * 优惠券相关请求模块
 */
// import qs from 'qs'
import request from '@/utils/request'

// 线上优惠券
export const getOnlineCouponsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/online_coupons/setup'
  })
}

export const getOnlineCoupons = (params) => {
  return request({
    method: 'GET',
    url: '/cms/online_coupons',
    params
  })
}

export const getOnlineCouponsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/online_coupons/${id}/preview`
  })
}

export const getOnlineCouponsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/online_coupons/${id}`
  })
}

export const getOnlineCouponsConfigurations = () => {
  return request({
    method: 'GET',
    url: '/cms/online_coupons/configurations'
  })
}

export const getOnlineCouponsShopList = () => {
  return request({
    method: 'GET',
    url: '/cms/shops/simple_list'
  })
}

export const getOnlineCouponsShopProduct = (id, params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/simple_list',
    params
  })
}

export const onlineCouponAddEdit = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/online_coupons/${id}`,
    data
  })
}

export const onlineCouponAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/online_coupons',
    data
  })
}

export const deleteOnlineCouponDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/online_coupons/${id}`
  })
}

export const updateOnlineCouponOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/online_coupons/${id}/online`
  })
}

export const updateOnlineCouponOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/online_coupons/${id}/offline`
  })
}

export const getOnlineCouponSimplelist = (id) => {
  return request({
    method: 'GET',
    url: '/cms/online_coupons/simple_list'
  })
}

// 线下优惠券
export const getOfflineCouponSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupons/setup'
  })
}

export const getOfflineCoupon = (params) => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupons',
    params
  })
}

export const getOfflineCouponEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/offline_coupons/${id}/preview`
  })
}

export const getOfflineCouponDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/offline_coupons/${id}`
  })
}

export const getOfflineCouponConfigurations = () => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupons/configurations'
  })
}

export const getOfflineCouponTartgetList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupons/target_list',
    params
  })
}

export const getOfflineCouponShopList = () => {
  return request({
    method: 'GET',
    url: '/cms/shops/simple_list'
  })
}

export const offlineCouponEdit = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/offline_coupons/${id}`,
    data
  })
}

export const offlineCouponAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/offline_coupons',
    data
  })
}

export const deleteOfflineCouponDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/offline_coupons/${id}`
  })
}

export const updateOfflineCouponOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/offline_coupons/${id}/online`
  })
}

export const updateOfflineCouponOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/offline_coupons/${id}/offline`
  })
}

// 线上优惠码活动
export const getOnlineVoucherEventSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/online_voucher_code_events/setup'
  })
}

export const getOnlineVoucherEvent = (params) => {
  return request({
    method: 'GET',
    url: '/cms/online_voucher_code_events',
    params
  })
}

export const getOnlineVoucherEventEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/online_voucher_code_events/${id}/preview`
  })
}

export const getOnlineVoucherEventDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/online_voucher_code_events/${id}`
  })
}

export const getOnlineVoucherEventConfigurations = () => {
  return request({
    method: 'GET',
    url: '/cms/online_voucher_code_events/configurations'
  })
}

export const getShopList = () => {
  return request({
    method: 'GET',
    url: '/cms/shops/simple_list'
  })
}

export const getShopProduct = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/simple_list',
    params
  })
}

export const onlineVoucherEventEdit = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/online_voucher_code_events/${id}`,
    data
  })
}

export const onlineVoucherEventAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/online_voucher_code_events',
    data
  })
}

export const deleteOnlineVoucherEventDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/online_voucher_code_events/${id}`
  })
}

export const updateOnlineVoucherEventOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/online_voucher_code_events/${id}/online`
  })
}

export const updateOnlineVoucherEventOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/online_voucher_code_events/${id}/offline`
  })
}
export const getSimplelist = (id) => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupons/simple_list'
  })
}

export const getOnlineCouponProduct = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/online_coupons/${id}/shop_items`,
    params
  })
}

export const onlineCouponProductAdd = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/online_coupons/${id}/add_applicable_shop_items`,
    data
  })
}

export const onlineCouponProductDelete = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/online_coupons/${id}/remove_applicable_shop_items`,
    data
  })
}

export const getCouponVoucherProduct = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/online_voucher_code_events/${id}/shop_items`,
    params
  })
}

export const couponVoucherProductAdd = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/online_voucher_code_events/${id}/add_applicable_shop_items`,
    data
  })
}

export const couponVoucherProductDelete = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/online_voucher_code_events/${id}/remove_applicable_shop_items`,
    data
  })
}

export const getSiteSimpList = () => {
  return request({
    method: 'GET',
    url: '/cms/sites/simple_list'
  })
}

/**
 * @name 线上优惠券兑换
 */

export const getCouponSnapshotsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/coupon_snapshots/setup'
  })
}

export const getCouponSnapshotsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/coupon_snapshots',
    params
  })
}

export const getCouponSnapshotsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/coupon_snapshots/${id}`
  })
}

/**
 * @name 线下优惠券兑换
 */

export const getOfflineCouponSnapshotsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupon_snapshots/setup'
  })
}

export const getOfflineCouponSnapshotsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/offline_coupon_snapshots',
    params
  })
}

export const getOfflineCouponSnapshotsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/offline_coupon_snapshots/${id}`
  })
}

export const getOfflineCouponSnapshotsExport = (params) => {
  return request({
    method: 'get',
    url: '/cms/offline_coupon_snapshots/export',
    params
  })
}

/**
* @name 优惠券礼包
*/

export const getCouponBundlesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/coupon_bundles/setup'
  })
}

export const getCouponBundlesList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/coupon_bundles',
    params
  })
}

export const getCouponBundlesEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/coupon_bundles/${id}/preview`
  })
}

export const getCouponBundlesCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/coupon_bundles/collections'
  })
}

export const getCouponBundlesDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/coupon_bundles/${id}`
  })
}

export const deleteCouponBundlesDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/coupon_bundles/${id}`
  })
}

export const postCouponBundlesFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/coupon_bundles',
    data
  })
}

export const putCouponBundlesFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/coupon_bundles/${id}`,
    data
  })
}

export const updateCouponBundlesOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/coupon_bundles/${id}/online`
  })
}

export const updateCouponBundlesOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/coupon_bundles/${id}/offline`
  })
}

export const getTicketPackageSimpList = (params) => {
  return request({
    method: 'GET',
    url: 'cms/ticket_packages/simple_list',
    params
  })
}

export const getTicketTypeSimpList = (params) => {
  return request({
    method: 'GET',
    url: 'cms/ticket_types/simple_list',
    params
  })
}

export const updateOfflineIdRank = (id) => {
  return request({
    method: 'POST',
    url: `cms/offline_coupons/${id}/top`
  })
}
