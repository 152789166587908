export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'poi_ids_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'coupon_type_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'discount_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'target_amount_f',
    label: '',
    Object: 'value'
  }, {
    prop: 'validity_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'description_1',
    label: '',
    Object: 'value'
  }, {
    prop: 'description',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'usage',
    label: '',
    Object: 'value',
    span: 12
  }, {
    prop: 'price_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'images',
    span: 24,
    image: true
  }]
}
